import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import SEO from 'components/shared/SEO';
import Layout from 'components/page/Layout';
import Tags from 'components/shared/Tags';
import TimeStamp from 'components/shared/TimeStamp';
import { H1, Meta } from 'styles/article';
import { BLACK } from 'constants/colors';

const Header = styled.div`
  color: #8c919b;
  padding: 2rem 0;
  text-align: center;
  text-transform: uppercase;
`;

const Author = styled(Meta)`
  padding-right: 1rem;
`;

const Excerpt = styled.p`
  font-size: 2.4rem !important;
  margin: 2rem 0 !important;
`;

const Nav = styled.nav`
  border-top: 0.3rem solid ${BLACK};
  border-bottom: 0.1rem solid ${BLACK};
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
`;

const Prev = styled.div`
  flex: 1;
  border-right: 0.1rem dotted ${BLACK};
  padding: 3rem 0;

  a {
    color: ${BLACK};
  }
`;

const Next = styled.div`
  flex: 1;
  text-align: right;
  padding: 3rem 0;
  text-decoration: none;

  a {
    color: ${BLACK};
  }
`;

const Title = styled.span`
  padding: 0 2rem;
`;

const Icon = styled.i`
  flex-direction: row;
`;

const Article = styled.article`
  max-width: 84rem;
  margin: 0 auto;
`;

function BlogPost({ data, pageContext }) {
  const { next, prev, recentPosts } = pageContext;
  const { body, frontmatter } = data.mdx;
  const { author, date, title, excerpt, featuredImage, tags } = frontmatter;
  const featuredImgFluid = featuredImage.childImageSharp.fluid;

  return (
    <Layout recentPosts={recentPosts}>
      <Article>
        <SEO
          description={excerpt}
          title={title}
          post={{
            image: featuredImgFluid,
          }}
        />
        <Header>
          <Author>By {author}</Author>
          {'/'}
          <Tags tags={tags} padded />
          {'/'}
          <TimeStamp date={date} />
          <H1>{title}</H1>
        </Header>
        <Img fluid={featuredImgFluid} alt={title} />
        <Excerpt>{excerpt}</Excerpt>
        <MDXRenderer>{body}</MDXRenderer>
        <Nav>
          {next && (
            <Prev>
              <Link to={next.frontmatter.path}>
                <Icon className="chevron left icon" />
                <Title>{next.frontmatter.title}</Title>
              </Link>
            </Prev>
          )}
          {prev && (
            <Next>
              <Link to={prev.frontmatter.path}>
                <Title>{prev.frontmatter.title}</Title>
                <Icon className="chevron right icon" />
              </Link>
            </Next>
          )}
        </Nav>
      </Article>
    </Layout>
  );
}

export const query = graphql`
  query($pathSlug: String!) {
    mdx(fields: { slug: { eq: $pathSlug } }) {
      frontmatter {
        author
        title
        excerpt
        tags
        date
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      body
    }
  }
`;

export default BlogPost;
